// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-mdx-deck-src-templates-deck-js": () => import("./../../../node_modules/gatsby-theme-mdx-deck/src/templates/deck.js" /* webpackChunkName: "component---node-modules-gatsby-theme-mdx-deck-src-templates-deck-js" */),
  "component---node-modules-gatsby-theme-mdx-deck-src-templates-decks-js": () => import("./../../../node_modules/gatsby-theme-mdx-deck/src/templates/decks.js" /* webpackChunkName: "component---node-modules-gatsby-theme-mdx-deck-src-templates-decks-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

